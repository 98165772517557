import { setId } from '@/scripts/helper';
import { fontSize, replaceFontSizeMap } from '@/assets/styleTool/map';

export const calcFontHeight = ({ font_size, line_spacing }) => {
  const replaceFontSize = replaceFontSizeMap[font_size];
  if (replaceFontSize) font_size = replaceFontSize;
  font_size = fontSize.find(item => item.value === font_size).key * 0.376;
  return typeof line_spacing === 'number' ? font_size * (line_spacing + 1) + 'mm' : parseFloat(line_spacing) + font_size + 'mm';
};

const getStyle = () => {
  return {
    font_size: '',
    font_family_cn: '',
    font_family_en: '',
    font_family_num: '',
    text_bold: '',
    font_color: {
      rgb: [],
      cmyk: []
    }
  };
};

export const getDefaultText = () => {
  return {
    node_id: setId(),
    node_type: 'text',
    content: {
      text: '',
      align: 'left'
    },
    margin: {
      before: '0mm'
    },
    style: getStyle()
  };
};

export const getDefaultImg = () => {
  return {
    node_id: setId(),
    node_type: 'image',
    content: {
      url: '',
      alias_url: ''
    },
    margin: {
      before: '0mm'
    },
    img_size: {
      width: '0mm',
      height: '0mm'
    }
  };
};

export const getDefaultPagenum = () => {
  return {
    node_id: setId(),
    node_type: 'page_number',
    content: {
      text: '@PN',
      url: '',
      alias_url: '',
      offset: ''
    },
    position: {
      x: '0mm',
      y: '0mm'
    },
    style: getStyle(),
    img_size: {
      width: '',
      height: ''
    }
  };
};

export const defaultFormat = function (cfg) {
  if (!cfg) return [];
  const { components: [{ default_style, page_cfg: { page_core_size, page_size, page_margin } }] } = cfg;
  return [
    {
      name: '空白（动静态文字）',
      type: 'text',
      img: require('./img/空白（动静态文字）.jpg'),
      children: [
        {
          name: '默认（空白）',
          img: require('./img/空白（动静态文字）.jpg'),
          data: {
            relative_margin: '0mm',
            offset: '0mm',
            margin_before: '0mm',
            data: [
              {
                node_id: setId(),
                node_type: 'text',
                content: {
                  text: '',
                  align: 'left'
                },
                margin: {
                  before: '0mm'
                },
                style: getStyle()
              },
              {
                node_id: setId(),
                node_type: 'text',
                content: {
                  text: '@CPLV1',
                  align: 'right'
                },
                margin: {
                  before: '0mm'
                },
                style: getStyle()
              },
            ]
          }
        },
        {
          name: '测试1',
          img: require('./img/空白（动静态文字）.jpg'),
          data: {
            relative_margin: '0mm',
            offset: '0mm',
            margin_before: '0mm',
            data: [
              {
                node_id: setId(),
                node_type: 'text',
                content: {
                  text: '',
                  align: 'left'
                },
                margin: {
                  before: '0mm'
                },
                style: getStyle()
              },
              {
                node_id: setId(),
                node_type: 'text',
                content: {
                  text: '@CPLV1',
                  align: 'right'
                },
                margin: {
                  before: '0mm'
                },
                style: getStyle()
              },
            ]
          }
        },
        {
          name: '测试2数据',
          img: require('./img/空白（动静态文字）.jpg'),
          data: {
            relative_margin: '0mm',
            offset: '0mm',
            margin_before: '0mm',
            data: [
              {
                node_id: setId(),
                node_type: 'text',
                content: {
                  text: '',
                  align: 'left'
                },
                margin: {
                  before: '0mm'
                },
                style: getStyle()
              },
              {
                node_id: setId(),
                node_type: 'text',
                content: {
                  text: '@CPLV1',
                  align: 'right'
                },
                margin: {
                  before: '0mm'
                },
                style: getStyle()
              },
            ]
          }
        }
      ]
    },
    {
      name: '空白（同版心宽）',
      type: 'image',
      img: require('./img/空白（同版心宽）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: '0mm',
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: '0mm'
            },
            img_size: {
              width: page_core_size.width,
              height: page_margin.top
            },
          }
        ]
      }
    },
    {
      name: '空白（同页宽）',
      type: 'image',
      img: require('./img/空白（同页宽）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: '0mm',
        margin_before: `-${page_margin.left}`,
        data: [
          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: '0mm'
            },
            img_size: {
              width: page_size.width,
              height: page_margin.top
            },
          }
        ]
      }
    },
    {
      name: '空白（版心宽+静态文字）',
      type: 'background-text',
      img: require('./img/空白（版心宽+静态文字）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: calcFontHeight(default_style),
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: '0mm'
            },
            img_size: {
              width: page_core_size.width,
              height: page_margin.top
            },
          },
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '',
              align: 'left'
            },
            margin: {
              before: '0mm'
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '空白（版心宽+动静态文字）',
      type: 'background-text',
      img: require('./img/空白（版心宽+动静态文字）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: calcFontHeight(default_style),
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: '0mm'
            },
            img_size: {
              width: page_core_size.width,
              height: page_margin.top
            },
          },
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '',
              align: 'left'
            },
            margin: {
              before: '0mm'
            },
            style: getStyle()
          },
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '@CPLV1',
              align: 'right'
            },
            margin: {
              before: '0mm'
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '空白（页宽+静态文字）',
      type: 'background-text',
      img: require('./img/空白（页宽+静态文字）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: calcFontHeight(default_style),
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: '0mm'
            },
            img_size: {
              width: page_size.width,
              height: page_margin.top
            },
          },
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '',
              align: 'left'
            },
            margin: {
              before: '0mm'
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '空白（页宽+动静态文字）',
      type: 'background-text',
      img: require('./img/空白（页宽+动静态文字）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: calcFontHeight(default_style),
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: '0mm'
            },
            img_size: {
              width: page_size.width,
              height: page_margin.top
            },
          },
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '',
              align: 'left'
            },
            margin: {
              before: '0mm'
            },
            style: getStyle()
          },
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '@CPLV1',
              align: 'right'
            },
            margin: {
              before: '0mm'
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '空白（插图+静态文字）',
      type: 'image-text',
      img: require('./img/空白（插图+静态文字）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: calcFontHeight(default_style),
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: '0mm'
            },
            img_size: {
              width: calcFontHeight(default_style),
              height: calcFontHeight(default_style)
            },
          },
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '',
              align: 'left'
            },
            margin: {
              before: calcFontHeight(default_style)
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '空白（插图+动静态文字）',
      type: 'image-text',
      img: require('./img/空白（插图+静态文字）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: calcFontHeight(default_style),
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: '0mm'
            },
            img_size: {
              width: calcFontHeight(default_style),
              height: calcFontHeight(default_style)
            },
          },
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '',
              align: 'left'
            },
            margin: {
              before: calcFontHeight(default_style)
            },
            style: getStyle()
          },
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '@CPLV1',
              align: 'right'
            },
            margin: {
              before: '0mm'
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '空白（页码+静态文字）',
      type: 'page_nubmer-text',
      img: require('./img/空白（页码+静态文字）.png'),
      data: {
        relative_margin: '0mm',
        offset: '0mm',
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '',
              align: 'left'
            },
            margin: {
              before: '0mm'
            },
            style: getStyle()
          },
          {
            node_id: setId(),
            node_type: 'page_number',
            content: {
              text: ''
            },
            position: {
              'x': (page_core_size.width) - 2,
              'y': '0mm'
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '空白（页码+动静态文字）',
      type: 'page_nubmer-text',
      img: require('./img/空白（页码+动静态文字）.png'),
      data: {
        relative_margin: '0mm',
        offset: '0mm',
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '',
              align: 'left'
            },
            margin: {
              before: '0mm'
            },
            style: getStyle()
          },
          {
            node_id: setId(),
            node_type: 'page_number',
            content: {
              text: '@PN'
            },
            position: {
              'x': `${parseFloat(page_core_size.width) - 2}mm`,
              'y': -parseFloat(default_style.font_size) * 0.376 + 'mm'
            },
            style: getStyle()
          },
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '@CPLV1',
              align: 'center'
            },
            margin: {
              before: '0mm'
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '空白（底图+页码+文字）',
      type: 'page_nubmer-text',
      img: require('./img/空白（底图+页码+文字）.png'),
      data: {
        relative_margin: '0mm',
        offset: calcFontHeight(default_style),
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '',
              align: 'left'
            },
            margin: {
              before: '0mm'
            },
            style: getStyle()
          },
          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: '0mm'
            },
            img_size: {
              width: page_size.width,
              height: page_margin.top
            },
          },
          {
            node_id: setId(),
            node_type: 'page_number',
            content: {
              text: '@PN'
            },
            position: {
              'x': `${parseFloat(page_core_size.width) - 2}mm`,
              'y': -parseFloat(default_style.font_size) * 0.376 + 'mm'
            },
            style: getStyle()
          },
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '@CPLV1',
              align: 'center'
            },
            margin: {
              before: '0mm'
            },
            style: getStyle()
          }
        ]
      }
    },
  ];
};
